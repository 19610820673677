.pt {
    margin: 10px 0;
    width: 100%;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.price-titles {
    background: $salmon;
    color: #FFF;
    padding: 2em 0;
    text-align: center;
    font-weight: bold;
    position: relative;
    text-transform: uppercase;
    font-size: 1.2em;
    padding: 1.5em .5em;
}

.price-price {
    background: #f9f9f9;
    padding: 1.5em .5em;
    text-align: center;
}

.price-price .num {
    font-size: 45px;
    font-weight: bold;
}

.price-price sup {
    bottom: 30px;
    line-height: 5px;
    vertical-align: baseline;
    _vertical-align: bottom;
    position: relative;
}

.price-price sub {
    top: 10px;
    line-height: 5px;
    vertical-align: baseline;
    _vertical-align: bottom;
    position: relative;
}

.price-row {
    padding: 1.5em .5em;
    background: #FF6363;
    text-align: center;
    flex: 1;
    transition-property: all .25s linear 0s;
    font-size:1em;
    &:nth-child(odd) {
        background: #fff;
    }
    &:nth-child(even) {
        background: #f9f9f9;
    }
    p {
        font-size: 1em;
    }
    i {
        color: #FF6363;
    }
}

.price-row i {
    margin-right: 5px;
    float: left;
}

.tables {
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    *zoom: 1;
    display: flex;
    flex-wrap: no-wrap;
    -webkit-backface-visibility: hidden;
}
