.btn {
    position: relative;
    display: inline-block;
    overflow: visible;
    margin: 5px 0;
    padding: 1em 1.5em 1em;
    border: 0;
    box-shadow: none;
    text-transform: uppercase;
    text-shadow: none;
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
    transition-property: all .1s ease-in 0s;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    background: $salmon;
}

.btn-table {
    width: 50%;
}

.btn-map {
    padding-top: 10px;
}

.btn-rsvp {
    width: 50%;
}

.price-btn-cont {
    padding: 1.5em .5em;
    text-align: center;
    background: #fff;
}
