body{
    background:#000;
}
#canvas{
    background:transparent;
    background-size:100%;
    z-index:990;
}


.bg {
    position: relative;
  width: 100%;
  height: 100%;
  background: url('../../images/wedding.jpg') center center no-repeat;
  background-size: cover;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  padding:0;
  margin: 0;
  
  &:after {
    content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(to bottom right,#000000,#941900);
	opacity: .6; 
  }
}

.v-headline {
    z-index: 999;
    color:#fff;
    justify-content:center;
    align-items:center;
    font-size:4.5em;
    position: absolute;
}