.faq-header{
  font-size: 2em;
  border-bottom: 1px dotted #ccc;
  padding: 1em 0;
}

.faq-c {
  border-bottom: 1px dotted #ccc;
  padding: 1em 0;
  width:100%;
}

.faq-t {
  line-height: 1em;
  color: $salmon; 
  float:left; 
  font-weight: 700; 
  padding-right: 0.3em; 
  transition: all 200ms;
}

.faq-o {
  transform: rotate(-45deg);
  transform-origin: 50% 50%;
  transition: all 200ms;
}

.faq-q {
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 100;
}

.faq-a {
  color: #666;
  display: none;
  padding-left: 1.5em;
  font-size:.6em;
  width:100%;
}
