h2{
  color:$salmon;
}

#map-canvas{
  overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
#map-canvas iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}



#panel {
  top: 10px;
  z-index: 5;
  padding-bottom:10px;
  font-weight:300;
  text-align:center;
  margin:0 auto;
  input{
    width:100%;
    text-align:center;
    border: 1px solid #e3e3e3;
  }
  label{
    font-size:1.5em;
  }
  .panel-inputs{
    justify-content:center;
    align-items:center;
  }
}