#countdown {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 20px 0 0px 0;
    color: #fff;
    border: 1px solid #adafb2;
    border-width: 1px 0;
    display:flex;
    font-family: 'Arial Narrow', Arial, sans-serif;
    li {
        margin: 0 -3px 0 0;
        padding: .5em;
        display:flex;
        justify-content:space-between;
        align-items:center;
        flex-direction:column;
        font-size: 72px;
        font-size: 4vw;
        text-align: center;
        @include bp(xs){
            font-size: 6vw;
        }
        .label {
            color: #adafb2;
            font-size: 18px;
            font-size: 1.5vw;
            text-transform: uppercase;
            display: block;
            @include bp(xs){
                font-size:3vw;
            }
        }
    }
}
