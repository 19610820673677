/* footer styles */

.footer {
    background-color: #484848 !important;
    padding-top: 10px;
    text-align:center;
    overflow-x:hidden;
}

.footer p {
    color: white;
    font-weight: bold;
    font-size: .75em;
}

.footer i {
    color: $salmon;
}

.social-links a {
    color: $salmon;
    padding: 0px 5px 0px 5px;
}

.social-links a:hover {
    color: white;
    transition: all 0.2s ease-in-out;
}

#copyright{
    color:#1c1c1c;
}
