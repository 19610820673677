*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

body {
    font-family: 'Poiret One', cursive;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    height: auto;

    @include bp(xlg) {
        max-width: 1400px;
        margin: 0 auto;
    }
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none !important;
    outline: 0 !important;
}

.headline {
    color: $white;
    font-size: 6vw;
}
.missed-date h2{
    font-size:2em;
}
.missed-date h3{
    color: $white;
}

.titles {
    margin-top: 1em;
    font-size: 2em;
}

.date {
    color: $white;
    font-family: 'Dancing Script', cursive;
    font-size: 1.5em;
}

h1 {
    color: $salmon;
    font-weight: bold;
}

p {
    font-size: 1.5em;
    color: #484848;
}

section {
    text-align: center;
    padding: .5em 0;
    overflow: auto;

    &:nth-child(odd) {
        background: $grey;
    }

    &:nth-child(even) {
        background: $white;
    }
}

img {
    width: 100%;
}

.content {
    margin: 2em;
}

.hidden {
    display: none !important;
}

.display {
    display: block;
}
