//media queries breakpoints
@mixin bp($class) {
  @if $class == xs {
    @media (max-width: 767px) { @content; }
  }
 
  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }
 
  @else if $class == md {
    @media (min-width: 992px) { @content; }
  }
 
  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }
  
  @else if $class == xlg {
    @media (min-width: 1400px) { @content; }
  }
 
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}