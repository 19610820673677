.header {
    background: url("/images/bg.jpg") no-repeat center center;
    background-size: cover;
    overflow:hidden;
    -webkit-backface-visibility: hidden;
    display:flex;
    height:100vh;
    width:auto;
    text-align:center;
    h1{
      @include bp(xs){
        font-size:20px;
      }
    }
}
.container{
  display:flex;
  justify-content:center;
  align-items:center;
  flex:1;
}
.opaque-bg {
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    padding: 1em;
    background-color: rgba(0, 0, 0, 0.4);
    text-align:center;
}

//About me
.avatar {
    border-radius: 50%;
    width: 20%;
    margin-bottom:1em;
    @include bp(xs){
      width:45%;
    }
}

.sign {
    font-family: 'Dancing Script', cursive;
    font-size: 1.5em;
}

.aboutme p {
    font-weight: 100;
    margin:0 auto;
    width:80%;
    @include bp(xs){
    width:90%;
    }
}

#rsvp{
  overflow-x:hidden;
}

//Registry
.logo{
  padding:1em;
  img{
    width:50%;
    @include bp(sm){
      width:50%;
    }
  }
}

.registry {
  padding-top:1.5em;
}

.fa-envelope{
  color: $salmon !important;
}

.asterisk{
  color:$salmon;
}