.hamburger {
    position: fixed;
    top: 5%;
    right: 5%;
    height: 27px;
    width: 35px;
    cursor: pointer;
    z-index: 101;
    transition: opacity .25s ease;
    -webkit-backface-visibility: hidden;
    @include bp(xlg) {
         max-width: 1400px;
         margin: 0 auto;
     }
    &:hover {
        opacity: .7;
    }
    &.active {
        .top {
            transform: translateY(11px) translateX(0) rotate(45deg);
            background: $salmon;
        }
        .middle {
            opacity: 0;
            background: $salmon;
        }
        .bottom {
            transform: translateY(-11px) translateX(0) rotate(-45deg);
            background: $salmon;
        }
    }
    span {
        background: $salmon;
        border: none;
        height: 5px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: all .35s ease;
        cursor: pointer;
        &:nth-of-type(2) {
            top: 11px;
        }
        &:nth-of-type(3) {
            top: 22px;
        }
    }
}

.overlay {
    position: fixed;
    background: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    z-index: 100;
    visibility: hidden;
    transition: opacity .35s, visibility .35s, height .35s;
    overflow: hidden;
    &.open {
        opacity: .9;
        visibility: visible;
        height: 100%;
        li {
            animation: fadeInRight .5s ease forwards;
            animation-delay: .35s;
            &:nth-of-type(2) {
                animation-delay: .4s;
            }
            &:nth-of-type(3) {
                animation-delay: .45s;
            }
            &:nth-of-type(4) {
                animation-delay: .50s;
            }
            &:nth-of-type(5) {
                animation-delay: .55s;
            }
            &:nth-of-type(6) {
                animation-delay: .60s;
            }
            &:nth-of-type(7) {
                animation-delay: .65s;
            }
        }
    }
    nav {
        position: relative;
        height: 50%;
        top: 35%;
        transform: translateY(-50%);
        font-size: 1.5em;
        font-weight: 400;
        text-align: center;
        @include bp(lg){
            font-size:3em;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        display: inline-block;
        position: relative;
        height: 100%;
        li {
            display: block;
            height: 25%;
            height: calc(100% / 4);
            min-height: 50px;
            position: relative;
            opacity: 0;
            a {
                display: block;
                position: relative;
                color: #fff;
                text-decoration: none;
                overflow: hidden;
                cursor: pointer;
                &:hover{
                    transition: .35s;
                    color:$salmon;
                }
                &:hover:after,
                &:focus:after,
                &:active:after {
                    width: 100%;
                    color: $salmon;
                }
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 0%;
                    transform: translateX(-50%);
                    height: 3px;
                    background: $salmon;
                    transition: .35s;
                    color: $salmon;
                }
            }
        }
    }
}
