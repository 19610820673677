#contact{
  color:$salmon;
i{
  color:$salmon;
}
}

//   /* contact us styles */

//   .fa-paper-plane {
//     color: #484848;
//   }

//   /* paper plane shake on hover */

//   .plane-animated {
//     animation-duration: 6s;
//     animation-fill-mode: both;
//     animation-iteration-count: infinite;
//     animation-play-state: paused;
//   }

//   .fa-paper-plane:hover {
//   Toggle our animation play state to running when we are hovering over our sticker
//   animation-play-state: running;
// }

//     @keyframes shake {
//     0%, 100% {transform: translateX(0);}
//     10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);}
//     20%, 40%, 60%, 80% {transform: translateX(10px);}
//   }
//     .shake {
//       animation-name: shake;
//     }


  /* input field styles */

// .contact-form {
//     padding: 0px 15px 0px 15px;
//   }


//   .form-control {
//     border: 2px solid rgba(0, 0, 0, 0.27);
//     width: 100%;
//     max-width: 100%;
//     font-size: 16px;
//     padding: 1em;
//     margin: .5em 0 2em 0;
//     font-weight: bold;
//   }

//   textarea {
//     min-height: 10em;
//   }

//   label {
//     display: inline-block;
//     max-width: 100%;
//     margin-bottom: 5px;
//     font-size: 18px;
//     text-transform: uppercase;
//     font-weight: 500;
//   }

//   .hidden{
//     display:none !important;
//   }

//   #submit {
//     outline: none;
//     padding: 12px;
//     min-width: 200px;
//     border-radius: 2px;
//     border: 2px solid #FF6363;
//     text-align: center;
//     font-size: 1em;
//     color: #FF6363;
//     background-color: white;
//     text-decoration: none;
//     text-transform: uppercase;
//     font-weight: bold;
//   }

//   #submit:hover {
//     background-color: #FF6363;
//     color: white;
//     -webkit-transition: all 0.3s ease-out;
//     -o-transition: all 0.3s ease-out;
//     -ms-transition: all 0.3s ease-out;
//     -moz-transition: all 0.3s ease-out;
//     transition: all 0.3s ease-out;
//   }

//   .success {
//   padding: 1em;
//   margin-bottom: 0.75rem;
//   text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
//   color: #468847;
//   background-color: #dff0d8;
//   border: 1px solid #d6e9c6;
//   -webkit-border-radius: 4px;
//      -moz-border-radius: 4px;
//           border-radius: 4px;
// }

// .error {
//   padding: 1em;
//   margin-bottom: 0.75rem;
//   text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
//   color: #b94a48;
//   background-color: #f2dede;
//   border: 1px solid rgba(185, 74, 72, 0.3);
//   -webkit-border-radius: 4px;
//      -moz-border-radius: 4px;
//           border-radius: 4px;
// }